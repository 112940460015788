import React, { useEffect } from "react";
import NavLink from "./_navLink";
import NavSection from "./navSection";
import logo from "../../static/img/logo.svg";
import logoWhite from "../../static/img/logo-white.svg";
import BackgroundImage from "gatsby-background-image";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

function Header({ hero, title, subtitle, pathname }) {
  let subtitleHTML = null;

  let getNavItems = (isLight) => {
    return (
      <nav className="bloom-flex bloom-items-center bloom--mx-4 md:bloom-justify-end md:bloom-w-1/2 bloom-nav-idex">
        <ul className="bloom-flex bloom-h-10">
          <li>
            <NavSection
              isLight={isLight}
              active={
                pathname?.includes(`services`) ||
                pathname?.includes(`work`) ||
                pathname?.includes(`contracting`)
              }
              id="work"
              title="Work"
            >
              <NavLink to={`/services`}>Services</NavLink>
              <NavLink to={`/work`}>Case studies</NavLink>
              <NavLink to={`/contracting`}>Contracting</NavLink>
            </NavSection>
          </li>
          <li>
            <NavSection
              isLight={isLight}
              active={pathname?.includes(`mission`) || pathname?.includes('jobs')}
              id="about"
              title="About"
            >
              <NavLink to={`/mission`}>Mission</NavLink>
              <NavLink to={`/jobs`}>Jobs</NavLink>
            </NavSection>
          </li>
          <li>
            <NavLink
              external={true}
              styles={`${
                isLight ? "bloom-text-white" : "bloom-text-black-full"
              } bloom-text-xl`}
              to={`mailto:hello@bloomworks.digital`}
            >
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink
              styles={`${
                isLight ? "bloom-text-white" : "bloom-text-black-full"
              } bloom-text-xl`}
              to={`https://medium.com/pollinator`}
            >
              Blog
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  };
  let contentDark = (
    <div className="bloom-py-4">
      <div className="bloom-flex bloom-flex-col md:bloom-flex-row md:bloom-items-center bloom-px-4 bloom-py-4">
        <div className="md:bloom-w-1/2 bloom-mb-4 md:bloom-mb-0">
          <a href="/">
            <img
              alt="Bloom Works"
              className={`bloom-h-6 sm:bloom-h-8`}
              src={logo}
            />
          </a>
        </div>
        {getNavItems(false)}
      </div>
    </div>
  );

  let contentLight = (
    <div className="bloom-py-4">
      <div className="bloom-flex bloom-flex-col md:bloom-flex-row md:bloom-items-center bloom-px-4 bloom-py-4">
        <div className="md:bloom-w-1/2 bloom-mb-4 md:bloom-mb-0">
          <a href="/">
            <img
              alt="Bloom Works"
              className={`bloom-h-6 sm:bloom-h-8`}
              src={logoWhite}
            />
          </a>
        </div>
        {getNavItems(true)}
      </div>
    </div>
  );

  if (subtitle) {
    subtitleHTML = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(subtitle)
      .toString();
  }

  return (
    <header>
      {hero && (
        <BackgroundImage
          backgroundColor={`transparent`}
          fluid={hero.image.childImageSharp.fluid}
          Tag="section"
        >
          {contentLight}
          <div>
            <div
              className={`bloom-max-w-4xl bloom-container bloom-mx-auto bloom-py-8 md:bloom-py-14 bloom-hero`}
            >
              <h1 className={`bloom-ht bloom-max-w-3xl bloom-text-white`}>
                {title}
              </h1>
              {subtitleHTML && (
                <div
                  className={`bloom-ht-link`}
                  dangerouslySetInnerHTML={{ __html: subtitleHTML }}
                ></div>
              )}
            </div>
            {(hero.attributionText || hero.attributionURL) && (
              <div
                className={`bloom-flex bloom-justify-end bloom-text-white bloom-py-1 bloom-px-3`}
              >
                {hero.attributionURL ? (
                  <a className={`bloom-underline`} href={hero.attributionURL}>
                    {hero.attributionText || hero.attributionURL}
                  </a>
                ) : (
                  hero.attributionText
                )}
              </div>
            )}
          </div>
        </BackgroundImage>
      )}
      {!hero && <div>{contentDark}</div>}
    </header>
  );
}

export default Header;
